import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import BottomBar from '../components/BottomBar';
import Products from '../components/Products';
import { Helmet } from 'react-helmet';
import 'normalize.css';
import Layout from '../components/Layout';

const DigitalBankingProduct = ({ data }) => {
  const bannerImg = data.ogImg.ogimage[4].file.url;

  return (
    <React.Fragment>
      <SEO
        title='Digital Banking'
        description={data.contentfulDigitalBanking.title}
        bannerImage={bannerImg}
        pathname='Digital Banking'
      />
      <Helmet>
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css'
        />
      </Helmet>
      <Layout>
        <Hero
          title={data.contentfulDigitalBanking.title}
          subtitle={data.contentfulDigitalBanking.subtitle}
          bgImage={data.contentfulDigitalBanking.heroImage.file.url}
          isProducts={true}
          isHomePage={true}
          isFull='is-fullheight-with-navbar'
        />
        <Products data={data.contentfulDigitalBanking.productIcons} />
        <BottomBar />
      </Layout>
    </React.Fragment>
  );
};

DigitalBankingProduct.propTypes = {
  data: PropTypes.shape({
    contentfulAboutUs: PropTypes.shape({
      heroImage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
    }),
    contentfulAsset: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.any,
      }),
    }),
    contentfulNavbar: PropTypes.shape({
      about: PropTypes.any,
      products: PropTypes.any,
    }),
    contentfulDigitalBanking: PropTypes.shape({
      heroImage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      subtitle: PropTypes.any,
      title: PropTypes.any,
    }),
  }),
};
export const query = graphql`
  {
    ogImg: contentfulOpenGraph {
      ogimage {
        file {
          url
          fileName
        }
      }
    }
    contentfulDigitalBanking {
      heroImage {
        file {
          url
        }
      }
      title
      subtitle
      productIcons {
        file {
          url
        }
        title
        description
      }
    }
  }
`;
export default DigitalBankingProduct;
